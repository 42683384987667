import { createRef, useEffect, useState } from "react";
import { Input, Button, Form, Typography, Spin } from "antd";
import type { InputRef } from "antd";
import { AccountUpdateFields, useManageAccountStore } from "../../../store";
import { updatePasswordUseCase } from "../../../useCases/userProfile/updatePasswordUseCase";
import { updateEmailUseCase } from "../../../useCases/userProfile/updateEmailUseCase";

const { Text } = Typography;

const OtpSubmissionForm = () => {
  const inputRefs = Array.from({ length: 6 }, () => createRef<InputRef>());
  const [otp, setOtp] = useState<string[]>(["", "", "", "", "", ""]);
  const [timeLeft, setTimeLeft] = useState(300); // 300 seconds = 5 minutes
  const [isExpired, setIsExpired] = useState(false);
  const [isCalling, setIsCalling] = useState<boolean>(false);

  const {
    setIsOTPVisible,
    newValue,
    setNewValue,
    setIsChangeSuccesfulVisible,
    accountFieldToUpdate,
    setAccountFieldToUpdate,
  } = useManageAccountStore();

  useEffect(() => {
    const otpExpiryTime = Date.now() + timeLeft * 1000; // Calculate when the OTP will expire

    // Timer function to update time left
    const timer = setInterval(() => {
      const remainingTime = Math.max(
        0,
        Math.floor((otpExpiryTime - Date.now()) / 1000)
      );

      setTimeLeft(remainingTime);

      if (remainingTime === 0) {
        setIsExpired(true);
        clearInterval(timer); // Stop the timer once expired
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleChange = (value: string, index: number) => {
    if (!/^\d*$/.test(value)) return; // Allow only digits
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Automatically move to the next input
    if (value && index < 5) {
      inputRefs[index + 1].current?.focus();
    }
  };

  const handleSubmit = async () => {
    const otpValue = otp.join("");

    if (!newValue) return;
    if (otpValue.length !== 6) return;

    // update password or email call
    setIsCalling(true);
    const res =
      accountFieldToUpdate === AccountUpdateFields.PASSWORD
        ? await updatePasswordUseCase({
            code: otpValue,
            password: newValue,
          })
        : accountFieldToUpdate === AccountUpdateFields.EMAIL
        ? await updateEmailUseCase({
            code: otpValue,
          })
        : null;
    console.log("res", res);
    setIsCalling(false);

    if (!res?.success) return;

    // on success
    setIsOTPVisible(false);
    setNewValue("");
    setIsChangeSuccesfulVisible(true);
    setAccountFieldToUpdate("");
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs[index - 1].current?.focus();
    }
  };

  // Format time left in mm:ss format
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      {!isExpired && (
        <>
          <Text style={{ fontSize: 12, fontWeight: 300 }}>
            A 6-digit one time password has been sent to your email.
          </Text>
          <Text style={{ fontSize: 14, fontWeight: 300, marginBottom: 20 }}>
            Enter the 6-digit OTP
          </Text>
        </>
      )}
      <Text
        style={{
          fontSize: 14,
          fontWeight: 300,
          marginBottom: 20,
          color: isExpired ? "red" : "black",
        }}
      >
        {isExpired
          ? "Your one time password has expired"
          : `Time remaining: ${formatTime(timeLeft)}`}
      </Text>
      {isExpired && (
        <Text
          style={{
            fontSize: 13,
            fontWeight: 300,
            marginTop: -20,
            marginBottom: 20,
          }}
        >
          Please click back to start again
        </Text>
      )}
      {!isExpired && (
        <Form onFinish={handleSubmit}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 8,
              marginBottom: 20,
            }}
          >
            {otp.map((_, index) => (
              <Input
                key={index}
                autoFocus={index === 0}
                maxLength={1}
                value={otp[index]}
                ref={inputRefs[index]}
                onChange={(e) => handleChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                style={{
                  width: 40,
                  height: 40,
                  textAlign: "center",
                  fontSize: 20,
                }}
                inputMode="numeric"
              />
            ))}
          </div>
          <Form.Item>
            {isCalling ? (
              <Spin />
            ) : (
              <Button type="primary" htmlType="submit" onClick={handleSubmit}>
                Submit OTP
              </Button>
            )}
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default OtpSubmissionForm;
