import { Button as AntdButton } from "antd";
export const Button = ({
  ghost = false,
  onClick,
  children,
  style,
}: {
  ghost?: boolean;
  onClick: () => void;
  children: any;
  style?: React.CSSProperties;
}) => {
  const color = "#1793C2";
  const invertedColor = "white";

  return (
    <AntdButton
      type="primary"
      style={{
        color: ghost ? color : invertedColor,
        borderColor: ghost ? color : invertedColor,
        backgroundColor: ghost ? invertedColor : color,
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </AntdButton>
  );
};
