import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "antd/es/layout/layout";
import { useAuth } from "../../contexts/auth";
import logo from "../../img/logo.png";
import PropertySelector from "../PropertySelector";
import { useAuthStore, usePropertyStore } from "../../store";
import React, { useEffect, useState } from "react";
import { commonConfig } from "../../config";
import { Avatar } from "antd";
import { getUserProfileUseCase } from "../../useCases/userProfile/getUserProfileUseCase";
import ManageAccountDrawer from "./ManageAccountDrawer";

const propertyPagePathRegex = /\/property\/([0-9]+)$/;

const isPropertyView = (path: string) => propertyPagePathRegex.test(path);
const HeaderNav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [clearProperties] = usePropertyStore((store) => [
    store.clearProperties,
  ]);
  const { userProfile, setUserProfile } = useAuthStore();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [avatarInitials, setAvatarInitials] = useState<string>("");

  const onDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    const fetchProfile = async () => {
      const profile = await getUserProfileUseCase();
      if (profile) {
        setUserProfile(profile);
      }
    };

    fetchProfile();
  }, [setUserProfile]);

  useEffect(() => {
    if (userProfile && userProfile.firstName[0] && userProfile.lastName[0]) {
      setAvatarInitials(
        `${userProfile.firstName[0]?.toUpperCase()}${userProfile.lastName[0]?.toUpperCase()}`
      );
    }
  }, [userProfile]);

  const handleLogout = () => {
    logout();
    clearProperties();
    setIsDrawerOpen(false);
    navigate(`/auth`);
  };

  const redirectToAdminPortal = (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    e.preventDefault();
    window.location.href = commonConfig.adminPortalUrl;
  };

  return location.pathname !== "/auth" ? (
    <>
      <Header
        style={{
          background: "#1F2937",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <img
          style={{ cursor: "pointer" }}
          onClick={redirectToAdminPortal}
          src={logo}
          alt="site logo"
        />
        {isPropertyView(location.pathname) && <PropertySelector />}
        <Avatar
          size={44}
          style={{
            backgroundColor: "#1793C2",
            cursor: "pointer",
          }}
          onClick={onDrawerOpen}
        >
          <span
            style={{
              fontSize: 24,
            }}
          >
            {avatarInitials}
          </span>
        </Avatar>
      </Header>
      <ManageAccountDrawer
        onDrawerClose={onDrawerClose}
        isDrawerOpen={isDrawerOpen}
        handleLogout={handleLogout}
      />
    </>
  ) : (
    <></>
  );
};

export default HeaderNav;
