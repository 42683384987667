import { Property } from "../../domain/device/entities/properties";
import { PropertiesAPI } from "../../services/hcnApi";

export const getPropertiesUseCase = async (): Promise<Property[]> => {
  const res = await PropertiesAPI.getProperties();
  const properties = res.map((prop: Property) => ({
    city: prop?.city,
    code: prop?.code,
    name: prop?.name,
    id: prop?.id,
    venueId: prop?.venueId,
    wakeTime: prop?.wakeTime,
    sleepTime: prop?.sleepTime,
  }));

  return properties;
};
