import { DevicesAPI, IGetDevicePreferencesParams } from "../../services/hcnApi";

export const getDevicePreferencesUseCase = async (
  params: IGetDevicePreferencesParams
) => {
  try {
    return DevicesAPI.getPreferences(params);
  } catch (err) {
    console.log(err);
  }
};
