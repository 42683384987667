import {
  Row,
  Switch,
  Tooltip,
  Typography,
  DatePicker,
  Slider,
  Select,
  Modal,
  Col,
} from "antd";
import ItemDescriptions from "../../ItemDescriptions";
import { Preferences } from "../../../domain/device/entities";
import { Button } from "../../Button";
import { useEffect, useRef, useState } from "react";
import { updateDevicePreferencesUseCase } from "../../../useCases/devices/updateDevicePreferencesUseCase";
import dayjs from "dayjs";
import { toast } from "react-toastify";

const { Title } = Typography;

const ALARM_SOUNDS = [
  { label: "Arpeggio", value: "arpeggio" },
  { label: "Bach_Badinerie", value: "bachbadinerie" },
  { label: "Electric_Piano", value: "electricpiano" },
  { label: "Soothing_Harp", value: "soothingharp" },
];

const SNOOZE_OPTIONS = [
  { label: "Off", value: false },
  { label: "5min", value: 5 * 60000 },
  { label: "10min", value: 10 * 60000 },
  { label: "30min", value: 30 * 60000 },
  { label: "1h", value: 60 * 60000 },
  { label: "2h", value: 120 * 60000 },
];

const defaultPreferences: Preferences = {
  alarm: {
    enabled: false,
    repeatDaily: false,
    date: dayjs().format("YYYY-MM-DD"),
    time: "08:00",
    volume: 50,
    fileName: ALARM_SOUNDS[0].value,
    snooze: false,
  },
  nightMode: {
    start: "23:00",
    end: "08:00",
    display: "Black",
    weather: false,
  },
};

type TabletSettingsProps = {
  preferences?: Preferences;
  setPreferences: (preferences: Preferences) => void;
  nightModeTimes?: {
    start: string;
    end: string;
  } | null;
  name?: string;
  propertyId?: string;
  room?: string;
};

const TabletSettings = ({
  preferences,
  setPreferences,
  nightModeTimes,
  name,
  propertyId,
  room,
}: TabletSettingsProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [hasShownTooltip, setHasShownTooltip] = useState(false);

  const firstRender = useRef(true);
  useEffect(() => {
    if (preferences === undefined) {
      return;
    }
    if (firstRender.current) {
      firstRender.current = false;
    } else if (!hasShownTooltip) {
      setShowTooltip(true);
      setHasShownTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 4000);
    }
  }, [preferences, hasShownTooltip]);

  const handleUpdateSettings = async () => {
    if (!room || !propertyId || !name) {
      toast.error("Missing required fields");
      return;
    }

    await updateDevicePreferencesUseCase({
      name,
      propertyId,
      room,
      preferences: currentPreferences,
    });
    firstRender.current = true;
    setHasShownTooltip(false);

    toast.success("Settings updated successfully");
  };

  const currentPreferences = {
    alarm: {
      enabled: preferences?.alarm?.enabled ?? defaultPreferences.alarm?.enabled,
      repeatDaily:
        preferences?.alarm?.repeatDaily ??
        defaultPreferences.alarm?.repeatDaily,
      date: preferences?.alarm?.date ?? defaultPreferences.alarm?.date,
      time:
        preferences?.alarm?.time ??
        nightModeTimes?.end ??
        defaultPreferences.alarm?.time,
      volume: preferences?.alarm?.volume ?? defaultPreferences.alarm?.volume,
      fileName:
        preferences?.alarm?.fileName ?? defaultPreferences.alarm?.fileName,
      snooze: preferences?.alarm?.snooze ?? defaultPreferences.alarm?.snooze,
    },
    nightMode: {
      start:
        preferences?.nightMode?.start ??
        nightModeTimes?.start ??
        defaultPreferences.nightMode?.start,
      end:
        preferences?.nightMode?.end ??
        nightModeTimes?.end ??
        defaultPreferences.nightMode?.end,
      display:
        preferences?.nightMode?.display ??
        defaultPreferences.nightMode?.display,
      weather:
        preferences?.nightMode?.weather ??
        defaultPreferences.nightMode?.weather,
    },
  };

  const showConfirmModal = () => {
    Modal.confirm({
      title: "Confirm Update",
      content: "Are you sure you want to update the settings?",
      onOk: handleUpdateSettings,
    });
  };

  return (
    <div>
      <Title level={4}>Tablet Settings</Title>
      <Row style={{ width: "100%" }}>
        <Col>
          <Title level={5} style={{ marginLeft: 16 }}>
            Alarm
          </Title>
          <ItemDescriptions
            items={[
              {
                label: "Enabled",
                children: (
                  <Switch
                    defaultChecked={false}
                    checked={currentPreferences.alarm?.enabled}
                    onChange={(checked) =>
                      setPreferences({
                        ...currentPreferences,
                        alarm: {
                          ...currentPreferences.alarm,
                          enabled: checked,
                          repeatDaily: !checked
                            ? false
                            : currentPreferences.alarm?.repeatDaily,
                        },
                      })
                    }
                  />
                ),
              },
              {
                label: "Repeat Daily",
                children: (
                  <Switch
                    defaultChecked={false}
                    checked={currentPreferences.alarm?.repeatDaily}
                    onChange={(checked) =>
                      setPreferences({
                        ...currentPreferences,
                        alarm: {
                          ...currentPreferences.alarm,
                          repeatDaily: checked,
                          enabled: checked
                            ? true
                            : currentPreferences.alarm?.enabled,
                        },
                      })
                    }
                  />
                ),
              },
              {
                label: "Date/Time",
                children: (
                  <DatePicker
                    format={
                      currentPreferences.alarm?.repeatDaily
                        ? "HH:mm"
                        : "YYYY-MM-DD HH:mm"
                    }
                    value={
                      currentPreferences.alarm?.repeatDaily
                        ? dayjs(currentPreferences.alarm?.time, "HH:mm")
                        : dayjs(
                            `${
                              currentPreferences.alarm?.date ||
                              dayjs().format("YYYY-MM-DD")
                            } ${currentPreferences.alarm?.time}`,
                            "YYYY-MM-DD HH:mm"
                          )
                    }
                    onChange={(date) => {
                      if (currentPreferences.alarm?.repeatDaily) {
                        setPreferences({
                          ...currentPreferences,
                          alarm: {
                            ...currentPreferences.alarm,
                            time: date?.format("HH:mm") || "08:00",
                          },
                        });
                      } else {
                        setPreferences({
                          ...currentPreferences,
                          alarm: {
                            ...currentPreferences.alarm,
                            date:
                              date?.format("YYYY-MM-DD") ||
                              dayjs().format("YYYY-MM-DD"), // Fallback to current date if null
                            time:
                              date?.format("HH:mm") ||
                              currentPreferences.alarm?.time ||
                              "08:00", // Maintain current time or fallback to default
                          },
                        });
                      }
                    }}
                    picker={
                      currentPreferences.alarm?.repeatDaily ? "time" : "date"
                    }
                    showTime={!currentPreferences.alarm?.repeatDaily} // Having this as true will hide the OK button when on repeatDaily mode for some reason so turn if off when repeatDaily is true
                  />
                ),
              },
              {
                label: "Volume",
                children: (
                  <Slider
                    min={0}
                    max={100}
                    value={currentPreferences.alarm?.volume}
                    onChange={(value) =>
                      setPreferences({
                        ...currentPreferences,
                        alarm: { ...currentPreferences.alarm, volume: value },
                      })
                    }
                  />
                ),
              },
              {
                label: "Sound",
                children: (
                  <Select
                    style={{ width: 200 }}
                    value={currentPreferences.alarm?.fileName}
                    onChange={(value) =>
                      setPreferences({
                        ...currentPreferences,
                        alarm: { ...currentPreferences.alarm, fileName: value },
                      })
                    }
                    options={ALARM_SOUNDS}
                  />
                ),
              },
              {
                label: "Snooze",
                children: (
                  <Select
                    style={{ width: 200 }}
                    value={currentPreferences.alarm?.snooze || false}
                    onChange={(value) =>
                      setPreferences({
                        ...currentPreferences,
                        alarm: { ...currentPreferences.alarm, snooze: value },
                      })
                    }
                    options={SNOOZE_OPTIONS}
                  />
                ),
              },
            ]}
          />
        </Col>
        <Col style={{ display: "flex", flexDirection: "column" }}>
          <Title level={5} style={{ marginLeft: 16 }}>
            Night Mode
          </Title>
          <ItemDescriptions
            items={[
              {
                label: "Start Time",
                children: (
                  <DatePicker
                    format="HH:mm"
                    value={dayjs(currentPreferences.nightMode?.start, "HH:mm")}
                    onChange={(date) =>
                      setPreferences({
                        ...currentPreferences,
                        nightMode: {
                          ...currentPreferences.nightMode,
                          start: date?.format("HH:mm") || "23:00",
                        },
                      })
                    }
                    picker="time"
                  />
                ),
              },
              {
                label: "End Time",
                children: (
                  <DatePicker
                    format="HH:mm"
                    value={dayjs(currentPreferences.nightMode?.end, "HH:mm")}
                    onChange={(date) =>
                      setPreferences({
                        ...currentPreferences,
                        nightMode: {
                          ...currentPreferences.nightMode,
                          end: date?.format("HH:mm") || "08:00",
                        },
                      })
                    }
                    picker="time"
                  />
                ),
              },
            ]}
          />
          <Button
            ghost
            style={{ margin: "0 16px 0" }}
            onClick={() => {
              Modal.confirm({
                title: "Reset Night Mode Settings",
                content:
                  "Are you sure you want to reset night mode settings to default values?",
                onOk: async () => {
                  firstRender.current = true;
                  const updatedPreferences = {
                    ...currentPreferences,
                    nightMode: {
                      ...defaultPreferences.nightMode,
                      start:
                        nightModeTimes?.start ||
                        defaultPreferences.nightMode?.start,
                      end:
                        nightModeTimes?.end ||
                        defaultPreferences.nightMode?.end,
                    },
                  };

                  if (!room || !propertyId || !name) {
                    toast.error("Missing required fields");
                    return;
                  }

                  await updateDevicePreferencesUseCase({
                    name,
                    propertyId,
                    room,
                    preferences: updatedPreferences,
                  });

                  setPreferences(updatedPreferences);
                  toast.success("Settings updated successfully");
                },
              });
            }}
          >
            Reset Night Mode
          </Button>
        </Col>
      </Row>
      <Tooltip
        title="Make sure to update settings after making changes"
        open={showTooltip}
        placement="rightTop"
      >
        <Button ghost onClick={showConfirmModal}>
          Update Settings
        </Button>
      </Tooltip>
    </div>
  );
};
export default TabletSettings;
