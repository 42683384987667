import { Drawer, Space, Typography } from "antd";
import { useAuthStore, useManageAccountStore } from "../../store";
import { Button } from "../Button";
import PasswordForm from "./PasswordForm";
import PasswordResetForm from "./PasswordResetForm";
import EmailResetForm from "./EmailResetForm";
import OtpSubmissionForm from "./OtpSubmissionForm";
import { useEffect, useRef } from "react";

const { Text } = Typography;

const ACCOUNT_MANAGEMENT_IDLE_TIME = 2 * 60 * 1000; // 2 mins

type DrawerProps = {
  onDrawerClose: () => void;
  isDrawerOpen: boolean;
  handleLogout: () => void;
};

const ManageAccountDrawer = ({
  onDrawerClose,
  isDrawerOpen,
  handleLogout,
}: DrawerProps) => {
  const { userProfile } = useAuthStore();
  const idleTimeout = useRef<number | null>(null);

  const drawerTitle =
    userProfile && userProfile.firstName && userProfile.lastName
      ? `${userProfile.firstName} ${userProfile.lastName}`
      : "User Profile";

  const {
    isManagingAccount,
    setIsManagingAccount,
    isPasswordFormVisible,
    setIsPasswordFormVisible,
    isResetFormsVisible,
    setIsResetFormsVisible,
    isOTPVisible,
    setIsOTPVisible,
    isChangeSuccessfulVisible,
    setIsChangeSuccesfulVisible,
    setNewValue,   
    setAccountFieldToUpdate, 
  } = useManageAccountStore();

  const resetManageAccountState = () => {
    setIsManagingAccount(false);
    setIsPasswordFormVisible(false);
    setIsResetFormsVisible(false);
    setIsOTPVisible(false);
    setIsChangeSuccesfulVisible(false);
    setNewValue("");
    setAccountFieldToUpdate("");
  };

  const onClose = () => {
    resetManageAccountState();
    onDrawerClose();
  };

  const onBackClick = () => {
    resetManageAccountState();
  };

  const handleManageAccount = () => {
    setIsManagingAccount(true);
    setIsPasswordFormVisible(true);
  };

  const handleIdleTimeout = () => {
    resetManageAccountState();
    setIsManagingAccount(true);
    setIsPasswordFormVisible(true);
    // Clear the timeout after it is triggered
    if (idleTimeout.current) {
      clearTimeout(idleTimeout.current);
      idleTimeout.current = null;
    }
  };

  const resetIdleTimer = () => {
    if (idleTimeout.current) {
      clearTimeout(idleTimeout.current);
    }

    idleTimeout.current = setTimeout(
      handleIdleTimeout,
      ACCOUNT_MANAGEMENT_IDLE_TIME
    ) as unknown as number;
  };

  useEffect(() => {
    if (isResetFormsVisible) {
      const events = [
        "mousemove",
        "mousedown",
        "keydown",
        "touchstart",
        "focus",
      ];
      // Reset the timer on any of these events
      events.forEach((event) => window.addEventListener(event, resetIdleTimer));

      resetIdleTimer(); // Start or reset the timer

      // Log the current timeout state for debugging purposes every second
      // const interval = setInterval(() => {     
      //   console.log("idleTimeout.current is", idleTimeout.current);
      // }, 1000);

      return () => {
        // clearInterval(interval);
        events.forEach((event) =>
          window.removeEventListener(event, resetIdleTimer)
        );
        if (idleTimeout.current) {
          clearTimeout(idleTimeout.current);
        }
      };
    }
  }, [isResetFormsVisible]);

  return (
    <Drawer
      title={drawerTitle}
      placement="right"
      onClose={onClose}
      open={isDrawerOpen}
    >
      <Space
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isManagingAccount ? (
          <>
            {isPasswordFormVisible && <PasswordForm />}
            {isResetFormsVisible && (
              <>
                <PasswordResetForm />
                <EmailResetForm />
              </>
            )}
            {isOTPVisible && <OtpSubmissionForm />}
            {isChangeSuccessfulVisible && (
              <Text style={{ fontSize: 14, fontWeight: 300 }}>
                Your changes have been applied successfully
              </Text>
            )}
            <Button ghost onClick={onBackClick}>
              Back
            </Button>
          </>
        ) : (
          <>
            <Button ghost onClick={handleManageAccount}>
              Manage account
            </Button>
            <Button ghost onClick={handleLogout}>
              Log out
            </Button>
          </>
        )}
      </Space>
    </Drawer>
  );
};

export default ManageAccountDrawer;
