export interface UserPermissions {
  assets: AssetPermissions[];
  venues: VenuePermission[];
}

export interface VenuePermission {
  id: string;
  type: number;
}

export enum ASSET_TYPE {
  locations = "locations",
  media = "media",
  properties = "properties",
  languages = "languages",
  device_dashboard = "device_dashboard",
}

export enum PERMISSION_TYPE {
  Read = "R",
  Create = "C",
  Edit = "E",
  Delete = "D",
  Publish = "P",
}

export interface AssetPermissions {
  name: ASSET_TYPE;
  permissions: PERMISSION_TYPE[];
}

export interface UserProfile {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  status: number;
  language: string;
  mobile: string;
  isAdmin: true;
  id: number;
  pin: string;
  created: string;
  updated: string;
  permissions: UserPermissions;
  locale: string;
  meta?: {
    passwordExpires: string;
  }
}
