import { DevicesAPI, IGetDevicePreferencesParams } from "../../services/hcnApi";

export const updateDevicePreferencesUseCase = async (
  params: IGetDevicePreferencesParams & { preferences: any }
) => {
  try {
    return DevicesAPI.updatePreferences(params);
  } catch (err) {
    console.log(err);
  }
};
